
    // ============================================================================================
    import { formCheck } from "@/mixins/form/formCheck";
    import { locale } from "@/mixins/translation/locale";

    // ============================================================================================
    export default {
        mixins:  [formCheck, locale],
        name: "LoginPage",
        layout: "authentication",
        transitions: "page",
        props: {
            componentFormData: {
                default: () => ({
                    email: "",
                    password: ""
                }),
                type: Object
            },
            componentFormIgnore: {
                default: () => ([]),
                type: Array
            }
        },
        data() {
            return {
                code: false,
                id: 0,
                invalidCode: false,
                postCode: 0,
                token: "",
                isSubmittingForm: false,
                showCustomerSupportLink: false
            }
        },
        beforeCreate() {
            if (this.$auth.loggedIn) this.$router.push("/dashboard");
        },
        methods: {
            async authForm() {
                this.formMessage ='';
                this.isSubmittingForm = true;
                this.formLoading = true;
                try {
                    await this.$axios.get(`/api/sanctum/csrf-cookie`);
                    this.loginForm();
                }catch(err) {
                    this.formMessage= this.$tc('messages.something_went_wrong_please_try_again');
                    this.isSubmittingForm = false;
                }
            },
            codeForm() {
                if (this.postCode && this.token && this.code) {
                    this.formLoading = true;

                    this.$axios.post("/api/code/check", {
                        id: this.id, code: +(this.postCode),
                    }).then(res => {
                        const token = res.data.data;

                        this.token = token.token;

                        this.loginUser();
                    }).catch(err => {
                        this.formMessage = this.$tc('messages.invalid_try_again');

                        this.invalidCode = true;

                        this.formLoading = false;

                        console.log(err);
                    });
                }
            },
            async loginForm() {
                this.isSubmittingForm = true;
                this.formErrorMessages=[];
                this.formErrors=[];
                try {
                    this.formData.lang = this.$i18n.locale;

                    const res = await this.$axios.post("/api/login", {...this.formData});
                    const token = res.data.data;
                    this.token = token.token;
                    if (token.id) this.id = token.id;
                    token.type === "user" ? this.loginUser() : this.setCode();
                } catch(err) {
                    this.formLoading = false;
                    this.isSubmittingForm = false;
                    const errors = err.response.data.errors;
                    if(errors){
                        Object.keys(errors).forEach((key) => {
                            this.formErrorMessages[key] = errors[key][0];
                            this.formErrors.push(key);
                        })
                    } else if (err.response.data) {
                        if (err.response.status === 401 && err.response.data.data.showSupportLink != null) {
                            this.showCustomerSupportLink = true;
                        }

                        this.formMessage = err.response.data.message;
                    }
                }
            },
            async loginUser() {
                try {
                    await this.$store.commit("shopify/RESET_STEPS");

                    await this.$auth.strategy.token.set(this.token);

                    await this.$auth.fetchUser();

                    if(this.$config.enableTranslation) {
                        await this.getLocale();
                    }

                    // if(this.$route.query && this.$route.query.shop) {
                    //     return this.$router.push("/shopify/signup?shop="+this.$route.query.shop);
                    // }
                    await this.$router.push("/dashboard");
                } catch(e) {
                    this.formMessage = this.$tc('messages.something_went_wrong');
                    this.isSubmittingForm = false;
                }

            },
            async setCode() {
                await this.$auth.strategy.token.set(this.token);

                this.code = true;
                this.formLoading = false;
            },
            updateCode(obj) {
                this.postCode = obj.value;
            }
        }
    }
