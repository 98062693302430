
    export default {
        name: "IconLoaderNew",
        props: {
            text: {
                default: true,
                type: Boolean
            }
        }
    }
