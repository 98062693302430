import { render, staticRenderFns } from "./index.vue?vue&type=template&id=31ce596c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogoNew: require('/usr/src/eventum-portal/components/icon/iconLogoNew.vue').default,FormInput: require('/usr/src/eventum-portal/components/form/FormInput.vue').default,IconRight: require('/usr/src/eventum-portal/components/icon/IconRight.vue').default,IconExclamation: require('/usr/src/eventum-portal/components/icon/IconExclamation.vue').default,IconLoaderNew: require('/usr/src/eventum-portal/components/icon/IconLoaderNew.vue').default})
